<template>
    <div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                    <div class="flex-a-c ">
                        <div class="zdhq">{{ checkedCompany.companyName }}</div>
                        <span class="ml10">管理文件>项目可行性分析报告 V1.0 </span>
                    </div>
                    <div class="flex-a-c">
                        <span>编号：</span>
                        <div class="zdhq">{{ checkedCompany.shortName }}</div>
                        <span>-GO-R003 </span>
                    </div>
                </div>
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <el-image class="companyLogo" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
            <div class="pl30 pr30 mt80 wordSetContent">
                <div class="wordContentTitle">项目可行性分析报告</div>
                <div class="wordTable w100p marginAuto">
                    <div class="wordTableRow h40">
                        <div class="itemTitle rightBorder flex-c-c w150">项目名称</div>
                        <div class="itemTitle rightBorder flex-c-c w420">
                            <div class="zdhq">{{ checkedProject.projectName }}</div>
                        </div>
                        <div class="itemTitle rightBorder flex-c-c w150">项目编号</div>
                        <div class="itemTitle flex-c-c w210">
                            <div class="zdhq">{{ checkedProject.year }}-{{ checkedProject.projectNo }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h40">
                        <div class="itemTitle rightBorder flex-c-c w150">编制部门</div>
                        <div class="itemContent rightBorder w420 flex-c-c">
                            <div>{{ checkedProject.departmentName }}</div>
                        </div>
                        <div class="itemTitle rightBorder flex-c-c w150">编制日期</div>
                        <div class="itemTitle flex-c-c w210">
                            <div class="zdhq">{{ formData.workDay }}</div>
                        </div>
                    </div>
                    <div class="wordTableRow h40">
                        <div class="itemTitle rightBorder flex-c-c w150">项目负责人</div>
                        <div class="itemTitle rightBorder w420 flex-c-c">
                            <div class="zdhq">{{ checkedProject.headName }}</div>
                        </div>
                        <div class="itemTitle rightBorder flex-c-c w150">项目来源</div>
                        <div class="itemTitle flex-c-c w210">
                            <div>自主研发</div>
                        </div>
                    </div>
                </div>
                <div class="mt40">
                    <div class="wordTable w100p marginAuto">
                        <div class="wordTableRow h40">
                            <div class="itemTitle fw600 rightBorder flex-c-c w150">项次</div>
                            <div class="itemTitle fw600 rightBorder flex-c-c w420">评估内容</div>
                            <div class="itemTitle fw600 rightBorder flex-c-c w150">可行性</div>
                            <div class="itemTitle fw600 flex-c-c w210">备注</div>
                        </div>
                        <div class="wordTableRow" style="height:137px">
                            <div class="itemTitle rightBorder flex-c-c w150">市场分析</div>
                            <div class="itemContent rightBorder w420 posre">
                                <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                                    style="height:137px" :rows="5" :placeholder="placeholderData.market"
                                    v-model="formData.market"
                                    @focus="keyPosition('market', '市场分析', placeholderData.market)"></el-input>
                            </div>
                            <div class="itemTitle rightBorder w150 flex-c-c">
                                <div class="el-icon-check iconCheck mr5"></div>
                                <div>可行</div>
                            </div>
                            <div class="itemTitle flex-c-c w210">

                            </div>
                        </div>
                        <div class="wordTableRow" style="height:137px">
                            <div class="itemTitle rightBorder textc w150">
                                <div class="mt40">产品概念</div>
                                <div>分析</div>
                            </div>
                            <div class="itemContent rightBorder w420 posre">
                                <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                                    style="height:137px;" :rows="5" :placeholder="placeholderData.product"
                                    v-model="formData.product"
                                    @focus="keyPosition('product', '产品概念分析', placeholderData.product)"></el-input>
                            </div>
                            <div class="itemTitle rightBorder w150 flex-c-c">
                                <div class="el-icon-check iconCheck mr5"></div>
                                <div>可行</div>
                            </div>
                            <div class="itemTitle flex-c-c w210">

                            </div>
                        </div>
                        <div class="wordTableRow" style="height:137px">
                            <div class="itemTitle rightBorder flex-c-c w150 posre">经济分析</div>
                            <div class="itemContent rightBorder w420 posre">
                                <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                                    style="height:137px" :rows="5" :placeholder="placeholderData.economic"
                                    v-model="formData.economic"
                                    @focus="keyPosition('economic', '经济分析', placeholderData.economic)"></el-input>
                            </div>
                            <div class="itemTitle rightBorder w150 flex-c-c">
                                <div class="el-icon-check iconCheck mr5"></div>
                                <div>可行</div>
                            </div>
                            <div class="itemTitle flex-c-c w210">

                            </div>
                        </div>
                        <div class="wordTableRow" style="height:137px">
                            <div class="itemTitle rightBorder textc w150">
                                <div class="mt40">技术可行性</div>
                                <div>难度分析</div>
                            </div>
                            <div class="itemContent rightBorder w420 posre">
                                <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit
                                    style="height:137px" :rows="5" placeholder="技术可行性难度分析" v-model="formData.difficult"
                                    @focus="keyPosition('difficult', '技术可行性难度分析', placeholderData.difficult)"></el-input>
                            </div>
                            <div class="itemTitle rightBorder w150 flex-c-c">
                                <div class="el-icon-check iconCheck mr5"></div>
                                <div>可行</div>
                            </div>
                            <div class="itemTitle flex-c-c w210">

                            </div>
                        </div>
                        <div class="wordTableRow h40">
                            <div class="itemTitle rightBorder flex-c-c w150">
                                <div>开发周期</div>
                            </div>
                            <div class="itemContent rightBorder w420 h40 flex-c-c ">
                                {{ formData.cycle }}个月
                            </div>
                            <div class="itemTitle rightBorder w150 flex-c-c">
                                <div class="el-icon-check iconCheck mr5"></div>
                                <div>可行</div>
                            </div>
                            <div class="itemTitle flex-c-c w210">
                            </div>
                        </div>
                        <div class="wordTableRow h60">
                            <div class="itemTitle rightBorder textc w150">
                                <div class="mt5">专利获取</div>
                                <div>可行性</div>
                            </div>
                            <div class="itemContent rightBorder flex-c-c w420 h60">
                                市场上尚未出现此种概念的产品
                            </div>
                            <div class="itemTitle rightBorder w150 flex-c-c">
                                <div class="el-icon-check iconCheck mr5"></div>
                                <div>可行</div>
                            </div>
                            <div class="itemTitle flex-c-c w210">

                            </div>
                        </div>
                        <div class="wordTableRow h200">
                            <div class="itemTitle rightBorder flex-c-c w150">
                                资源分析
                            </div>
                            <div style="width:787px;">
                                <div class="h40 childTable bottomBorder">
                                    <div class="itemContent rightBorder flex-c-c w420">
                                        研发人力资源
                                    </div>
                                    <div class="itemTitle rightBorder w150 flex-c-c">
                                        <div class="el-icon-check iconCheck mr5"></div>
                                        <div>可行</div>
                                    </div>
                                    <div class="itemTitle flex-c-c w210">
                                    </div>
                                </div>
                                <div class="h40 childTable bottomBorder">
                                    <div class="itemContent rightBorder flex-c-c w420 h40">
                                        研发仪器设备
                                    </div>
                                    <div class="itemTitle rightBorder w150 flex-c-c">
                                        <div class="el-icon-check iconCheck mr5"></div>
                                        <div>可行</div>
                                    </div>
                                    <div class="itemTitle flex-c-c w210">
                                    </div>
                                </div>
                                <div class="h40 childTable bottomBorder">
                                    <div class="itemContent rightBorder flex-c-c w420 h40">
                                        生产人力资源
                                    </div>
                                    <div class="itemTitle rightBorder w150 flex-c-c">
                                        <div class="el-icon-check iconCheck mr5"></div>
                                        <div>可行</div>
                                    </div>
                                    <div class="itemTitle flex-c-c w210">
                                    </div>
                                </div>
                                <div class="h40 childTable bottomBorder">
                                    <div class="itemContent rightBorder flex-c-c w420 h40">
                                        生产仪器设备
                                    </div>
                                    <div class="itemTitle rightBorder w150 flex-c-c">
                                        <div class="el-icon-check iconCheck mr5"></div>
                                        <div>可行</div>
                                    </div>
                                    <div class="itemTitle flex-c-c w210">
                                    </div>
                                </div>
                                <div class="h40 childTable">
                                    <div class="itemContent rightBorder flex-c-c w420 h40">
                                        其他
                                    </div>
                                    <div class="itemTitle rightBorder w150 flex-c-c">
                                        <div class="el-icon-check iconCheck mr5"></div>
                                        <div>可行</div>
                                    </div>
                                    <div class="itemTitle flex-c-c w210">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="wordTableRow h40">
                            <div class="itemTitle rightBorder flex-c-c w150">
                                <div>其他分析</div>
                            </div>
                            <div class="itemContent rightBorder w420 h40">

                            </div>
                            <div class="itemTitle rightBorder w150 flex-c-c">
                                <div class="iconCheck mr5"></div>
                                <div>可行</div>
                            </div>
                            <div class="itemTitle flex-c-c w210">
                            </div>
                        </div>
                        <div class="wordTableRow h100">
                            <div class="itemTitle rightBorder flex-c-c w150">
                                <div>综合评价</div>
                            </div>
                            <div class="itemContent rightBorder flex-c-c w200 h100">
                                项目评审组意见
                            </div>
                            <div class="itemContent rightBorder w160 ml10 h100">
                                <div class="flex-a-c mt18">
                                    <div class="iconCheck el-icon-check mr5"></div>
                                    <div>可行</div>
                                </div>
                                <div class="flex-a-c">
                                    <div class="iconCheck mr5"></div>
                                    <div>不可行</div>
                                </div>
                            </div>
                            <div class="itemTitle w420">
                                <div class="flex-a-c mt5">
                                    <div class="iconCheck el-icon-check mr5"></div>
                                    <div>同意</div>
                                </div>
                                <div class="flex-a-c">
                                    <div class="flex-a-c">
                                        <div class="iconCheck mr5"></div>
                                        <div>不同意</div>
                                    </div>
                                    <div class="ml50">
                                        总经理：
                                    </div>
                                </div>
                                <div class="flex-a-c justify-content-end">
                                    <el-date-picker v-model="formData.fileDay" value-format="yyyy-MM-dd"
                                        format="yyyy年MM月dd日" placeholde="文件日期" type="date" placeholder="选择日期">
                                    </el-date-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { getDataTimeSec } from '@/utils'
export default {
    props: ['projectData', 'assignment', 'modelData'],
    data() {
        return {
            checkedProject: {},
            checkedCompany: {},
            formData: {
                day: "",//编制日期
                market: "",//市场分析
                product: "",//产品概念分析
                economic: "",//经济分析
                difficult: "",//技术可行性难度分析
                cycle: "",//开发周期
                fileDay: "",//文件日期
            },
            placeholderData: {
                market: "",//市场分析
                product: "",//产品概念分析
                economic: "",//经济分析
                difficult: "",
            },
            gptIcon: this.$store.state.ossUrl + "yimai_photos/iconFont/chatGTP02.png",
            departmentData: {},
        }
    },
    watch: {
        //监听赋值数据，赋值
        assignment: {
            handler(data) {
                if (data.key) {
                    if (data.key.indexOf("_") != -1) {
                        let keys = data.key.split("_");//0 key 1 列表key 2 下标
                       //-console.log(keys);
                        this.formData[keys[0]][keys[2]][keys[1]] = data.gptResult;
                        // for (let row of this.formData[keys[0]]) {
                        //     row[keys[1]] = data.gptResult;
                        // }
                    } else {
                        this.formData[data.key] = data.gptResult;
                    }
                    if (this.$refs[data.key]) {
                        this.$refs[data.key].focus();
                    }
                }
            },
            deep: true,
            immediate: true
        },
        //监听填写数据
        modelData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.createData();
                    for (let key in data) {
                        let arrKey = []
                        let noKey = ['workDay', 'cycle'];
                        if (noKey.indexOf(key) == -1) {
                            if (arrKey.indexOf(key) != -1) {
                                this.formData[key] = data[key] ? JSON.parse(data[key]) : [];
                            } else {
                                this.formData[key] = data[key] ? data[key] : "";
                            }
                        }

                    }
                } else {
                    this.formData = {
                        day: "",//编制日期
                        market: "",//市场分析
                        product: "",//产品概念分析
                        economic: "",//经济分析
                        difficult: "",//技术可行性难度分析
                        cycle: "",//开发周期
                        fileDay: "",//文件日期
                        workDay: "",
                    };
                    this.createData();
                }
            },
            deep: true
        }
    },
    // mounted() {
    //     this.createData();
    // },
    methods: {
        /**@method 定位要赋值的数据 */
        keyPosition(key, title, problem = "") {
            this.$emit("keyPosition", { key, title, problem });
        },
        createData() {
            let checkedProject = this.projectData;//项目数据
           //-console.log(this.projectData);
            this.checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
            this.checkedProject = {
                ...checkedProject,
                //研发开始年
                year: checkedProject.startDay ? checkedProject.startDay.slice(0, 4) : "",
                //编制日期
                compileDate: this.convertChinese((new Date(checkedProject.projectDay).getTime() + (86400000 * 10))),
                //研发开始只显示到月
                startMonth: checkedProject.startDay ? checkedProject.startDay.slice(0, 7) : "",
                //研发结束只显示到月
                endMonth: checkedProject.endDay ? checkedProject.endDay.slice(0, 7) : "",
                //转中文
                projectDayText: this.convertChinese(checkedProject.projectDay),
                startDayText: this.convertChinese(checkedProject.startDay),
                endDayText: this.convertChinese(checkedProject.endDay),
            };
            this.placeholderData = {
                market: `我司立项${checkedProject.projectName}，请写出该项目的可行性市场分析，不要分点，150字以内。`,//市场分析
                product: `我司立项${checkedProject.projectName}，请写出该项目的产品概念分析，不要分点，150字以内`,//产品概念分析
                economic: `我司立项${checkedProject.projectName}，请写出该项目的经济分析，不要分点，150字以内`,//经济分析
                difficult: `我司立项${checkedProject.projectName}，请写出该项目的技术可行性难度分析，不要分点，150字以内。`,
            }
            let y = new Date(checkedProject.endDay).getFullYear() - new Date(checkedProject.startDay).getFullYear();
            let m = ((y * 12) + (new Date(checkedProject.endDay).getMonth() + 1)) - (new Date(checkedProject.startDay).getMonth() + 1);
            this.formData.cycle = parseInt(m + 1);
            this.formData.workDay = this.convertChinese(new Date(checkedProject.projectDay).getTime() + (86400000 * 10));
        },
        convertChinese(time) {
            if (time) {
                let newDate = new Date(time)
                let y = newDate.getFullYear();
                let m = newDate.getMonth() + 1;
                let d = newDate.getDate();
                return y + '年' + (m < 10 ? '0' + m : m) + '月' + (d < 10 ? '0' + d : d) + '日'
            }
            return "";
        },
    }
}
</script>
<style lang="scss" scoped>
.companyLogo {
    height: 100px;
    position: absolute;
    top: 100px;
    left: 80px;
}

.gptIcon {
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 25px;
    width: 25px;
    z-index: 4;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
}

.zdhq {
    // color: #E6A23C !important;
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;

    /deep/input {
        border: none;
        color: #E6A23C; // #000;
    }

    /deep/.el-textarea__inner,
    /deep/.allShow .el-input__inner {
        border: none;
        padding: 0px 15px;
        color: #E6A23C; // #000;
    }
}

.wordSetContent div {
    font-size: 18px;
    letter-spacing: 2px;
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 auto;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .wordContentTitle {
        font-size: 30px;
        text-align: center;
        padding: 10px 0;
        font-weight: 600;
    }

    .modelHeader {
        div {
            font-size: 16px;
        }

        .modelHeaderTitle {
            border-bottom: 1px solid #000;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;
        font-size: 18px;

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordCodeTable {
        width: 350px;
        margin-left: auto;
        border: 2px solid #000;
        margin-bottom: 150px;

        .wordCodeTitle {
            width: 40%;
            height: 43px;
            border-right: 1px solid #000;
            font-size: 18px;
        }

        .wordCodeContent {
            width: 60%;
            height: 43px;
            font-size: 16px;

            /deep/input {
                height: 42px;
                line-height: 42px;
            }
        }
    }

    .projectTitle div {
        font-size: 45px !important;
    }

    .projectTitle {
        margin-bottom: 200px;
    }

    .wordSetContent {
        .wordTable {
            border: 1px solid #000;

            .wordTableRow {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #000;
                position: relative;

                .childTable {
                    display: flex;
                    align-items: center;
                }

                .tool {
                    display: block;
                    position: absolute;
                    right: -61px;
                    width: 60px;
                    height: 60px;
                    background: #f1f2f4;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);

                    .eicon {
                        font-size: 18px;
                        color: #666;
                        cursor: pointer;
                        height: 30px;
                        width: 30px;
                        text-align: center;
                        line-height: 30px;
                    }

                    .el-icon-delete {
                        color: #F56C6C;
                    }
                }

                .allShow {
                    width: 100%;
                    height: 40px;
                    border: none;
                }

                /deep/.allShow input {
                    width: 100%;
                    font-size: 18px !important;
                }

                /deep/input {
                    height: 39px;
                    line-height: 39px;
                    font-size: 18px !important;
                }

                /deep/.el-input__icon {
                    line-height: 39px;
                    font-size: 18px !important;
                }

                .iconCheck {
                    border: 1px solid #000;
                    height: 20px;
                    width: 20px;
                }

                .itemTitle {
                    font-size: 18px;
                    padding: 0 10px;
                    height: 100%;
                    overflow: hidden;
                }

                .itemContent {
                    min-height: 40px;
                    overflow: hidden;

                    div {
                        font-size: 18px;
                    }

                    /deep/.el-range-editor--small.el-input__inner {
                        height: 58px;
                    }

                    /deep/.el-range-separator {
                        padding: 0;
                        line-height: 50px;
                        font-size: 18px;
                        width: 20px;
                    }

                    /deep/.el-input__inner {
                        padding-right: 0;
                    }

                    /deep/.el-range__close-icon {
                        font-size: 18px;
                        line-height: 53px;
                    }
                }

                .rightBorder {
                    border-right: 1px solid #000;
                }

                .topBorder {
                    border-top: 1px solid #000;
                }

                .leftBorder {
                    border-left: 1px solid #000;
                }

                .bottomBorder {
                    border-bottom: 1px solid #000;
                }

                .wordSign {
                    height: 100%;
                    padding-top: 250px;

                    .wordSignContent {
                        text-align: justify;
                        margin-left: 50px;
                    }
                }
            }

            .wordTableRow:hover .tool {
                display: block;
            }

            .wordTableRow:last-child {
                border: none;
            }
        }

        .iconCheck {
            border: 1px solid #000;
            height: 20px;
            width: 20px;
        }

        .underline {
            border-bottom: 1px solid #000;
        }

        .wordTableBigTitle {
            padding: 15px 10px;
            border: 1px solid #000;
            font-weight: 600;
        }

        .insert {
            border-top: none;
        }

        .wordTableTitle {
            padding: 15px 10px;
            border: 1px solid #000;
        }

        .wordTableTitle.insert {
            border-top: none;
        }

        .wordSetTextarea {
            border: 1px solid #000;
            border-top: none;

            .wordTextareaContent {
                text-indent: 45px;
                text-align: justify;
                line-height: 40px;
            }
        }
    }
}
</style>