<template>
  <div>
    <div class="showModelContent">
      <div class="modelHeader">
        <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
          <div class="flex-a-c">
            <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
            <div class="zdhq">{{ checkedCompany.companyName }}</div>
            <span class="ml10">管理文件>项目结题验收报告 V1.0</span>
          </div>
          <div class="flex-a-c">
            <span>编号：</span>
            <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
            <div class="zdhq">{{ checkedCompany.shortName }}</div>
            <span>-GO-R002</span>
          </div>
        </div>
        <div class="flex-a-b-c mb10">
          <div class="squareLeft"></div>
          <div class="squareRight"></div>
        </div>
      </div>
      <el-image
        class="companyLogo"
        v-if="checkedCompany.logo"
        :src="checkedCompany.logo"
      ></el-image>
      <div class="pl30 pr30 wordSetContent">
        <div class="wordCodeTable">
          <div class="flex-a-c" style="border-bottom: 1px solid #000">
            <div class="wordCodeTitle flex-c-c">立项编号</div>
            <div class="flex-c-c wordCodeContent">
              <!-- <el-input v-model="formData.name" class="w100" placeholder="编号"></el-input>
                            <span>-RD</span>
                            <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
              <div class="zdhq">
                {{ checkedProject.year }}-{{ checkedProject.projectNo }}
              </div>
            </div>
          </div>
          <div class="flex-a-c">
            <div class="wordCodeTitle flex-c-c">申报编号</div>
            <div class="flex-c-c wordCodeContent">
              <div class="zdhq">{{ checkedProject.code }}</div>
            </div>
          </div>
        </div>
        <div class="projectTitle">
          <div class="textc pjTitle mt10 mb10">项目结题验收报告</div>
        </div>
        <div class="wordTable w750 marginAuto">
          <div class="wordTableRow h60">
            <div class="itemTitle rightBorder w160">项目名称</div>
            <div class="itemTitle flex-c-c w600">
              <!-- <el-input v-model="formData.name" class="w400" placeholder="项目名称"></el-input> -->
              <div class="zdhq">{{ checkedProject.projectName }}</div>
            </div>
          </div>
          <div class="wordTableRow h60">
            <div class="itemTitle rightBorder w160">项目负责人</div>
            <div class="itemTitle flex-c-c w600">
              <!-- <el-input v-model="formData.name" class="allShow" placeholder="负责人"></el-input> -->
              <div class="zdhq">{{ checkedProject.headName }}</div>
            </div>
          </div>
          <div class="wordTableRow h60">
            <div class="itemTitle rightBorder w160">立项日期</div>
            <div class="itemTitle flex-c-c w600">
              <div class="zdhq">{{ checkedProject.projectDayText }}</div>
            </div>
          </div>
          <div class="wordTableRow h60">
            <div class="itemTitle rightBorder w160">验收日期</div>
            <div class="itemTitle flex-c-c w600">
              <div class="zdhq">{{ checkedProject.endDayText }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="wordPage flex-a-b-c">
        <div class="squareLeft"></div>
        <div>1</div>
        <div class="squareRight"></div>
      </div>
    </div>
    <div class="showModelContent">
      <div class="modelHeader">
        <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
          <div class="flex-a-c">
            <div class="zdhq">{{ checkedCompany.companyName }}</div>
            <span class="ml10">管理文件>项目结题验收报告 V1.0</span>
          </div>
          <div class="flex-a-c">
            <span>编号：</span>
            <div class="zdhq">{{ checkedCompany.shortName }}</div>
            <span>-GO-R002</span>
          </div>
        </div>
        <div class="flex-a-b-c mb10">
          <div class="squareLeft"></div>
          <div class="squareRight"></div>
        </div>
      </div>
      <div class="pl30 pr30 wordSetContent">
        <div class="wordContentTitle">项目结题验收报告</div>
        <div class="wordTable w100p marginAuto">
          <div class="wordTableRow h60">
            <div class="itemTitle rightBorder w150">项目名称</div>
            <div class="itemTitle rightBorder flex-c-c w380">
              <div class="zdhq">{{ checkedProject.projectName }}</div>
            </div>
            <div class="itemTitle rightBorder w160">项目起止时间</div>
            <div class="itemTitle flex-c-c w250">
              <div class="zdhq">
                {{ checkedProject.startMonth }}至{{ checkedProject.endMonth }}
              </div>
            </div>
          </div>
          <div class="wordTableRow h60">
            <div class="itemTitle rightBorder w150">项目预算</div>
            <div class="itemContent rightBorder w380 flex-c-c">
              <div class="zdhq">
                {{
                  checkedProject.budget
                    ? checkedProject.budget
                    : checkedProject.money
                }}万元
              </div>
            </div>
            <div class="itemTitle rightBorder w160">项目实际投资</div>
            <div class="itemTitle flex-c-c w250">
              <el-input
                v-model="formData.realMoney"
                class="w164"
                type="number"
                placeholder="项目实际投资"
              ></el-input
              >万元
              <!-- {{ formData.realMoney }}万元 -->
            </div>
          </div>
          <div class="wordTableRow h60">
            <div class="itemTitle rightBorder w150">立项编号</div>
            <div class="itemTitle rightBorder w380 flex-c-c">
              <div class="zdhq">
                {{ checkedProject.year }}-{{ checkedProject.projectNo }}
              </div>
            </div>
            <div class="itemTitle rightBorder w160">项目验收时间</div>
            <div class="itemTitle flex-c-c w250">
              <div>{{ checkedProject.endDayText }}</div>
            </div>
          </div>
          <div class="wordTableRow h60">
            <div class="itemTitle rightBorder w150">项目申请人</div>
            <div class="itemTitle rightBorder w380 flex-c-c">
              <div class="zdhq">{{ checkedProject.proposerName }}</div>
            </div>
            <div class="itemTitle rightBorder w160">验收负责人</div>
            <div class="itemTitle flex-c-c w250">
              <div class="zdhq">{{ checkedProject.headName }}</div>
            </div>
          </div>

          <div class="wordTableRow setContainer" style="min-height: 100px">
            <div class="itemTitle w150">项目组成员</div>
            <div
              class="itemTitle leftBorder"
              style="width: calc(100% - 140px); min-height: 100px"
            >
              <div class="zdhq">
                <span
                  class="mr10 zdhq"
                  v-for="(row, index) in memberList"
                  :key="index"
                  >{{ row.name }}</span
                >
              </div>
            </div>
            <!-- <div class="tool">
                            <div class="flex-c-c">
                                <i class="eicon el-icon-caret-top" @click="topMove(index, 'testList')"></i>
                                <i class="eicon el-icon-plus" @click="addRow(index, 'testList')"></i>
                            </div>
                            <div class="flex-c-c">
                                <i class="eicon el-icon-caret-bottom" @click="bottomMove(index, 'testList')"></i>
                                <i class="eicon el-icon-delete" @click="delRow(index, 'testList')"></i>
                            </div>
                        </div> -->
          </div>
        </div>
        <div>
          <div class="wordTableBigTitle insert">一、项目主要指标完成情况</div>
          <div class="wordTable w100p marginAuto insert">
            <div class="wordTableRow h60">
              <div class="itemTitle rightBorder flex-c-c w600 fw600">
                项目主要验收内容
              </div>
              <div
                class="itemTitle flex-c-c fw600"
                style="width: calc(100% - 600px)"
              >
                <!-- <el-input v-model="formData.job" class="allShow" placeholder="项目申请人"></el-input> -->
                项目完成情况
              </div>
            </div>
            <div class="wordTableRow2">
              <div class="w600 bottomBorder">
                <div class="itemTitle rightBorder bottomBorder h40">
                  1、项目开发内容
                </div>
                <div
                  class="itemContent rightBorder flex-c-c w600"
                  style="height: 182px; width: calc(100% - 600px)"
                >
                  <el-input
                    type="textarea"
                    class="textIndent"
                    maxlength="1000"
                    show-word-limit
                    @focus="
                      keyPosition(
                        'content',
                        '研究开发内容',
                        placeholderData.content
                      )
                    "
                    :placeholder="placeholderData.content"
                    :rows="6"
                    v-model="formData.content"
                  ></el-input>
                </div>
              </div>
              <div
                class="itemContent bottomBorder flex-c-c"
                style="height: 222px; width: calc(100% - 600px)"
              >
                <el-input
                  type="textarea"
                  class="textIndent"
                  maxlength="1000"
                  show-word-limit
                  @focus="
                    keyPosition(
                      'contentFinish',
                      '项目开发内容完成情况',
                      placeholderData.contentFinish
                    )
                  "
                  :placeholder="placeholderData.contentFinish"
                  :rows="7"
                  v-model="formData.contentFinish"
                ></el-input>
              </div>
            </div>
            <div class="wordTableRow2">
              <div class="w600 bottomBorder">
                <div class="itemTitle rightBorder bottomBorder h40">
                  2、技术、经济指标
                </div>
                <div
                  class="itemContent rightBorder flex-c-c w600"
                  style="height: 182px; width: calc(100% - 600px)"
                >
                  <el-input
                    type="textarea"
                    maxlength="1000"
                    show-word-limit
                    @focus="
                      keyPosition(
                        'index',
                        '技术、经济指标',
                        placeholderData.index
                      )
                    "
                    :placeholder="placeholderData.index"
                    :rows="6"
                    v-model="formData.index"
                  ></el-input>
                </div>
              </div>
              <div
                class="itemContent bottomBorder flex-c-c"
                style="height: 222px; width: calc(100% - 600px)"
              >
                <el-input
                  type="textarea"
                  class="textIndent"
                  maxlength="1000"
                  show-word-limit
                  @focus="
                    keyPosition(
                      'indexFinish',
                      '技术、经济指标完成情况',
                      placeholderData.indexFinish
                    )
                  "
                  :placeholder="placeholderData.indexFinish"
                  :rows="7"
                  v-model="formData.indexFinish"
                ></el-input>
              </div>
            </div>
            <div class="wordTableRow2">
              <div class="w600">
                <div class="itemTitle rightBorder bottomBorder h40">
                  3、研究开发成果
                </div>
                <div
                  class="itemContent rightBorder flex-c-c w600"
                  style="height: 242px; width: calc(100% - 600px)"
                >
                  <el-input
                    type="textarea"
                    class="textIndent"
                    maxlength="1000"
                    show-word-limit
                    @focus="
                      keyPosition(
                        'result',
                        '研究开发成果',
                        placeholderData.result
                      )
                    "
                    :placeholder="placeholderData.result"
                    :rows="8"
                    v-model="formData.result"
                  ></el-input>
                </div>
              </div>
              <div
                class="itemContent"
                style="height: 282px; width: calc(100% - 600px); padding: 0"
              >
                <el-input
                  type="textarea"
                  class="textIndent"
                  maxlength="1000"
                  show-word-limit
                  @focus="
                    keyPosition(
                      'remark',
                      '研究开发成果完成情况',
                      placeholderData.remark
                    )
                  "
                  :placeholder="placeholderData.remark"
                  :rows="9"
                  v-model="formData.remark"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wordPage flex-a-b-c">
        <div class="squareLeft"></div>
        <div>2</div>
        <div class="squareRight"></div>
      </div>
    </div>
    <div class="showModelContent">
      <div class="modelHeader">
        <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
          <div class="flex-a-c">
            <div class="zdhq">{{ checkedCompany.companyName }}</div>
            <span class="ml10">管理文件>项目结题验收报告 V1.0</span>
          </div>
          <div class="flex-a-c">
            <span>编号：</span>
            <div class="zdhq">{{ checkedCompany.shortName }}</div>
            <span>-GO-R002</span>
          </div>
        </div>
        <div class="flex-a-b-c mb10">
          <div class="squareLeft"></div>
          <div class="squareRight"></div>
        </div>
      </div>
      <div class="pl30 pr30 wordSetContent">
        <div>
          <div class="wordTableBigTitle flex-a-b-c">
            <div>二、验收资料</div>
            <el-button type="primary" @click="addInitFileList()"
              >生成默认内容</el-button
            >
          </div>
          <div class="wordTable w100p marginAuto insert">
            <div class="wordTableRow h60">
              <div class="itemTitle rightBorder w230 flex-c-c">文档类型</div>
              <div class="itemTitle rightBorder w550 flex-c-c">文档名称</div>
              <div class="itemTitle w160 flex-c-c">验收结果</div>
            </div>
            <vuedraggable v-model="formData.fileList" v-bind="dragOptions">
              <transition-group>
                <section
                  class="wordTableRow h60"
                  v-for="(row, index) in formData.fileList"
                  :key="index+'tg'"
                >
                  <div class="itemContent rightBorder w230 flex-c-c">
                    <el-input
                      v-model="row.type"
                      class="allShow"
                      @focus="
                        keyPosition(
                          'fileList_type_' + index,
                          '验收资料-文档类型',
                          '验收资料-文档类型'
                        )
                      "
                      placeholder="文档类型"
                    ></el-input>
                  </div>
                  <div class="itemContent rightBorder w550 flex-c-c">
                    <el-input
                      v-model="row.name"
                      class="allShow"
                      @focus="
                        keyPosition(
                          'fileList_name_' + index,
                          '验收资料-文档名称',
                          '验收资料-文档名称'
                        )
                      "
                      placeholder="文档名称"
                      type="textarea"
                      maxlength="250"
                      show-word-limit
                      :rows="2"
                    ></el-input>
                  </div>
                  <div class="itemContent flex-c-c w160">
                    {{ row.result }}
                  </div>
                  <div class="tool">
                    <div class="flex-c-c">
                      <i
                        class="eicon el-icon-plus"
                        @click="addRow(index, 'fileList')"
                      ></i>
                    </div>
                    <div class="flex-c-c">
                      <i
                        class="eicon el-icon-delete"
                        @click="delRow(index, 'fileList')"
                      ></i>
                    </div>
                  </div>
                </section>
              </transition-group>
            </vuedraggable>
          </div>
        </div>
        <div>
          <div class="wordTableBigTitle">三、项目验收组意见</div>
          <div class="wordTableTitle insert textc">验收组意见</div>
          <div class="wordSetTextarea h200">
            <!-- <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit @focus="keyPosition('market', '市场分析',placeholderData.market)" placeholder="项目主要内容" :autosize="{ minRows: 6 }" v-model="formData.content"
                            ></el-input> -->
          </div>
        </div>
        <div>
          <div class="wordTableTitle insert textc">验收组成员</div>
          <div class="wordSetTextarea setContainer h200">
            <!-- <el-input type="textarea" class="textIndent" maxlength="1000" show-word-limit @focus="keyPosition('market', '市场分析',placeholderData.market)" placeholder="项目关键核心技术" :autosize="{ minRows: 6 }" v-model="formData.core"
                            ></el-input> -->
            <!-- <div class="tool">
                            <div class="flex-c-c">
                                <i class="eicon el-icon-caret-top" @click="topMove(index, 'testList')"></i>
                                <i class="eicon el-icon-plus" @click="addRow(index, 'testList')"></i>
                            </div>
                            <div class="flex-c-c">
                                <i class="eicon el-icon-caret-bottom" @click="bottomMove(index, 'testList')"></i>
                                <i class="eicon el-icon-delete" @click="delRow(index, 'testList')"></i>
                            </div>
                        </div> -->
          </div>
        </div>
        <div class="posre">
          <div class="wordTableBigTitle insert">四、公司领导意见</div>
          <div class="wordTable w100p marginAuto insert">
            <div class="wordTableRow h60">
              <div class="itemTitle rightBorder w50p">研发中心意见</div>
              <div class="itemTitle w50p">总经理审批意见</div>
            </div>
            <div class="wordTableRow">
              <div class="wordSign rightBorder w50p pb100">
                <div class="ml100 mb60">
                  <div class="mb20">负责人签名：</div>
                  <div>日 <span class="ml60">期：</span></div>
                </div>
              </div>
              <div class="wordSign w50p pb100">
                <div class="ml100">
                  <div class="mb20">总经理签名：</div>
                  <div>日 <span class="ml60">期：</span></div>
                </div>
                <div class="mt30 ml300">（公司章）</div>
              </div>
            </div>
          </div>
          <el-image
            class="companySeal"
            v-if="checkedCompany.seal"
            :src="checkedCompany.seal"
          ></el-image>
        </div>
      </div>
      <div class="wordPage flex-a-b-c">
        <div class="squareLeft"></div>
        <div>3</div>
        <div class="squareRight"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNormalWordDataByPdId } from "@/api/projectAdmin";
import { getProjectMoneyCountList } from "@/api/projectMoney";
import vuedraggable from "vuedraggable";
export default {
  components: { vuedraggable },
  props: ["projectData", "assignment", "modelData"],
  data() {
    return {
      dragOptions: {
        //拖拽配置
        animation: 200,
      },
      checkedProject: {},
      checkedCompany: {},
      memberList: [],
      formData: {
        realMoney: "", //项目实际投资
        content: "", //研究开发内容
        index: "", //技术、经济指标
        result: "", //研究开发成果
        contentFinish: "",
        indexFinish:
          "项目已试用或应用于多个客户，技术及经济指标达到了预期效果。",
        remark:
          "项目已经进行成果转化并且经测试体验良好，功能基本初步实现，达到了初步效果，实现销售并取得了良好的经济效益和社会效益。",
        fileList: [],
      },
      placeholderData: {
        realMoney: "", //项目实际投资
        content: "", //研究开发内容
        index: "", //技术、经济指标
        result: "", //研究开发成果
        contentFinish: "",
        indexFinish: "",
        remark: "",
      },
      userTableJobDefault: {
        1: [
          {
            key: "项目经理",
            content:
              "项目总负责人，主要负责项目统筹，项目架构、需求分析、资源协调等。",
          },
          {
            key: "技术总监",
            content:
              "主要负责项目技术攻关，产品规划和设计，对产品的技术要求进行评估，确保产品质量和竞争力。",
          },
          {
            key: "产品经理",
            content:
              "主要负责项目前期调研、需求分析、需求研究、项目设计、优化等。",
          },
          {
            key: "研发工程师",
            content: "主要负责项目设计、产品开发、优化等。",
          },
          {
            key: "检测工程师",
            content: "主要负责对设备质量和性能进行检测、控制及测试。",
          },
        ],
        2: [
          {
            key: "项目经理",
            content:
              "项目总负责人，主要负责项目统筹，项目架构、需求分析、资源协调等。",
          },
          {
            key: "技术总监",
            content:
              "主要负责项目技术攻关，产品规划和设计，对产品的技术要求进行评估，确保产品质量和竞争力。",
          },
          {
            key: "产品经理",
            content: "主要负责项目产品规划设计、项目架构、流程设计、市场调研。",
          },
          {
            key: "前端工程师",
            content: "主要负责项目前端技术，页面设计，功能维护等。",
          },
          {
            key: "研发工程师",
            content:
              "主要负责研发产品的基础构架设计、开发和升级维护、程序编码。",
          },
          {
            key: "测试工程师",
            content: "主要负责产品测试、产品问题反馈、客户意见反馈。",
          },
        ],
        3: [
          {
            key: "项目经理",
            content:
              "项目总负责人，主要负责项目统筹，项目架构、需求分析、资源协调等。",
          },
          {
            key: "技术总监",
            content:
              "主要负责项目技术攻关，产品规划和设计，对产品的技术要求进行评估，确保产品质量和竞争力。",
          },
          {
            key: "产品经理",
            content: "主要负责项目产品规划设计、项目架构、流程设计、市场调研。",
          },
          {
            key: "研发工程师",
            content: "主要负责项目前端技术，页面设计，功能维护等。",
          },
          {
            key: "研发工程师",
            content:
              "主要负责研发产品的基础构架设计、开发和升级维护、程序编码。",
          },
          { key: "检测工程师", content: "主要负责技术验证，产品质量检测。" },
        ],
        4: [
          {
            key: "项目经理",
            content:
              "项目总负责人，主要负责项目统筹，项目架构、需求分析、资源协调等。",
          },
          {
            key: "技术总监",
            content:
              "主要负责项目技术攻关，产品规划和设计，对产品的技术要求进行评估，确保产品质量和竞争力。",
          },
          {
            key: "产品经理",
            content:
              "主要负责项目前期调研、需求分析、需求研究、项目设计、优化等。",
          },
          {
            key: "研发工程师",
            content: "主要负责项目设计、产品开发、优化等。",
          },
          {
            key: "检测工程师",
            content: "主要负责对设备质量和性能进行检测、控制及测试。",
          },
        ],
      },
      researchDefault: {
        //1：硬件，2：软件，3：技术，4：设备
        1: `公司建立了健全的研发组织构架，成立了企业技术研发中心。并根据本项目特点组建了项目研发团队，主要由产品设计人员，策划人员、研发工程师组等组成的项目研发组，设置多名专职的系统架构、设计、研发、测试人员参与本项目的研发。公司制定了完善、规范的《科研项目管理制度》及《研发中心管理办法》、《研发准备金管理办法》等管理制度，为项目研发提供了基础保障。`,
        2: `公司建立了健全的研发组织构架，成立了企业技术研发中心。并根据本项目特点组建了项目研发团队，主要由产品设计人员，策划人员、软件工程师、平面设计师组等组成的项目研发组 ，设置多名专职的系统架构、设计、研发、测试人员参与本系统的研发。公司制定了完善、规范的《科研项目管理制度》及《研发中心管理办法》、《研发准备金管理办法》等管理制度，为项目研发提供了基础保障。<br>同时公司配备了适宜的硬件设备、开发环境及服务器，为本系统的研发打造优质的开发及测试环境。`,
        3: `公司建立了健全的研发组织构架，成立了企业技术研发中心。并根据本项目特点组建了项目研发团队，主要由产品设计人员，策划人员、研发工程师组等组成的项目研发组，设置多名专职的系统架构、设计、研发、测试人员参与本项目的研发。公司制定了完善、规范的《科研项目管理制度》及《研发中心管理办法》、《研发准备金管理办法》等管理制度，为项目研发提供了基础保障。`,
        4: `公司建立了健全的研发组织构架，成立了企业技术研发中心。并根据本项目特点组建了项目研发团队，主要由产品设计人员，策划人员、研发工程师组等组成的项目研发组，设置多名专职的系统架构、设计、研发、测试人员参与本项目的研发。公司制定了完善、规范的《科研项目管理制度》及《研发中心管理办法》、《研发准备金管理办法》等管理制度，为项目研发提供了基础保障。`,
      },
      researchMoney: 0,
    };
  },
  watch: {
    //监听赋值数据，赋值
    assignment: {
      handler(data) {
        if (data.key) {
          if (data.key.indexOf("_") != -1) {
            let keys = data.key.split("_"); //0 key 1 列表key 2 下标
            this.formData[keys[0]][keys[2]][keys[1]] = data.gptResult;
          } else {
            this.formData[data.key] = data.gptResult;
          }
          if (this.$refs[data.key]) {
            this.$refs[data.key].focus();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    //监听填写数据
    modelData: {
      async handler(data) {
        //-console.log(data);
        if (data && JSON.stringify(data) != "{}") {
          await this.createData();
          for (let key in data) {
            let arrKey = ["fileList"];
            if (key == "realMoney") {
              this.formData[key] = data[key] ? data[key] : this.formData[key];
            } else if (arrKey.indexOf(key) != -1) {
              this.formData[key] = data[key] ? JSON.parse(data[key]) : [];
            } else {
              this.formData[key] = data[key] ? data[key] : "";
            }
          }
        } else {
          this.formData = {
            realMoney: 0, //项目实际投资
            content: "", //研究开发内容
            index: "", //技术、经济指标
            result: "", //研究开发成果
            contentFinish: "",
            indexFinish:
              "项目已试用或应用于多个客户，技术及经济指标达到了预期效果。",
            remark:
              "项目已经进行成果转化并且经测试体验良好，功能基本初步实现，达到了初步效果，实现销售并取得了良好的经济效益和社会效益。",
            fileList: [],
          };
          this.createData();
        }
      },
      deep: true,
    },
  },
  methods: {
    /**@method 生成默认数据 */
    addInitFileList() {
      this.formData.fileList = [
        {
          type: "作业指导(说明)书",
          name: `${this.checkedProject.projectName}项目作业指导书`,
          result: "通过",
        },
      ];
      //1：硬件，2：软件，3：技术，4：设备
      if (this.checkedProject.projectType == 1) {
        this.formData.fileList.push({
          type: "专利证书",
          name: `${this.checkedProject.projectName}项目专利证书或申请受理通知书`,
          result: "通过",
        });
        this.formData.fileList.push({
          type: "项目图纸",
          name: `${this.checkedProject.projectName}项目设计图纸`,
          result: "通过",
        });
      } else if (this.checkedProject.projectType == 2) {
        this.formData.fileList.push({
          type: "知识产权证书",
          name: `${this.checkedProject.projectName}项目著作权证书`,
          result: "通过",
        });
        this.formData.fileList.push({
          type: "源代码",
          name: `${this.checkedProject.projectName}项目源代码压缩包`,
          result: "通过",
        });
      } else if (this.checkedProject.projectType == 3) {
        this.formData.fileList.push({
          type: "专利证书",
          name: `${this.checkedProject.projectName}项目专利证书或申请受理通知书`,
          result: "通过",
        });
      } else if (this.checkedProject.projectType == 4) {
        this.formData.fileList.push({
          type: "专利证书",
          name: `${this.checkedProject.projectName}项目专利证书或申请受理通知书`,
          result: "通过",
        });
        this.formData.fileList.push({
          type: "项目图纸",
          name: `${this.checkedProject.projectName}项目设计图纸`,
          result: "通过",
        });
      }
      this.formData.fileList.push({
        type: "测试(检测报告)",
        name: `${this.checkedProject.projectName}项目测试报告(检测报告)`,
        result: "通过",
      });
    },
    /**@method 定位要赋值的数据 */
    keyPosition(key, title, problem = "") {
      this.$emit("keyPosition", { key, title, problem });
    },
    createData() {
      let checkedProject = this.projectData; //项目数据
      this.checkedCompany = JSON.parse(
        sessionStorage.getItem("checkedProjectCompany")
      );
      this.checkedProject = {
        ...checkedProject,
        year: checkedProject.startDay
          ? checkedProject.startDay.slice(0, 4)
          : "",
        startMonth: checkedProject.startDay
          ? checkedProject.startDay.slice(0, 7)
          : "",
        endMonth: checkedProject.endDay
          ? checkedProject.endDay.slice(0, 7)
          : "",
        startDayText: this.convertChinese(checkedProject.startDay),
        endDayText: this.convertChinese(checkedProject.endDay),
        projectDayText: this.convertChinese(checkedProject.projectDay),
        code: checkedProject.projectDay
          ? checkedProject.projectDay.slice(0, 4) +
            checkedProject.projectNo.replace(/RD/gi, "")
          : "",
      };
      if (
        checkedProject.projectUserList &&
        checkedProject.projectUserList.length
      ) {
        let memberList = checkedProject.projectUserList;
        let memberValue = [];
        for (let i in memberList) {
          let row = memberList[i];
          let obj = {
            num: Number(i) + 1,
            name: row.userName,
            work: row.work,
            education: row.education,
            job: "",
          };
          for (let item of this.userTableJobDefault[
            checkedProject.projectType
          ]) {
            if (item.key === row.work && !obj.job) {
              obj.job = item.content;
              break;
            }
          }
          memberValue.push(obj);
        }
        this.memberList = memberValue;
      }
      this.addInitFileList();
      this.placeholderData = {
        content: "项目开发内容", //研究开发内容
        index: "技术、经济指标", //技术、经济指标
        result: "研究开发成果", //研究开发成果
        contentFinish: "项目开发内容完成情况",
        indexFinish: "技术、经济指标完成情况",
        remark: "研究开发成果完成情况",
      };
      this.formData.contentFinish = `已完成“${checkedProject.projectName}”，功能达到了预期效果。`;
      return Promise.all([
        new Promise((open) => {
          getNormalWordDataByPdId({
            pdId: this.projectData.pdId,
            modelType: 1,
          }).then((res) => {
            if (res.data) {
              if (res.data.wordData.content) {
                this.formData.content = res.data.wordData.content; //研究开发内容
              }
              if (res.data.wordData.innovate) {
                this.formData.index = res.data.wordData.innovate; //技术、经济指标
              }
              if (res.data.wordData.result) {
                this.formData.result = res.data.wordData.result; //研究开发成果
              }
            }
            open();
          });
        }),
        new Promise((open) => {
          getProjectMoneyCountList({
            year: checkedProject.startDay.slice(0, 4),
            pcId: checkedProject.pcId,
          }).then((res) => {
            if (res.data.projectDataList) {
              for (let row of res.data.projectDataList) {
                if (row.projectNo == checkedProject.projectNo) {
                  this.formData.realMoney = (row.researchMoney / 10000).toFixed(
                    2
                  );
                  break;
                }
              }
            }
            open();
          });
        }),
      ]);
    },
    convertChinese(time) {
      if (time) {
        let newDate = new Date(time);
        let y = newDate.getFullYear();
        let m = newDate.getMonth() + 1;
        let d = newDate.getDate();
        return (
          y +
          "年" +
          (m < 10 ? "0" + m : m) +
          "月" +
          (d < 10 ? "0" + d : d) +
          "日"
        );
      }
      return "";
    },
    /**@method 整理序号 */
    getTableCode() {
      for (let key in this.memberList) {
        this.$set(this.memberList[key], "num", Number(key) + 1);
      }
    },
    addRow(i, dataKey) {
      if (dataKey == "fileList") {
        this.formData.fileList.push({
          type: "",
          name: "",
          result: "通过",
        });
      }
    },
    delRow(i, dataKey) {
      if (this.formData.fileList.length > 1) {
        if (dataKey == "fileList") {
          this.formData.fileList.splice(i, 1);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.companyLogo {
  height: 100px;
  position: absolute;
  top: 100px;
  left: 80px;
}

.companySeal {
  height: 200px;
  width: 200px;
  position: absolute;
  bottom: 130px;
  right: 30px;
}

.zdhq {
  // color: #E6A23C !important;
}

.showModelContent div,
.showModelContent span {
  color: #000;
  border-color: #000;

  /deep/input {
    border: none;
    color: #e6a23c; // #000;
  }

  /deep/.el-textarea__inner,
  /deep/.el-input__inner {
    border: none;
    color: #e6a23c; // #000;
    padding: 0px 15px;
  }
}

.wordSetContent div {
  font-size: 20px;
  letter-spacing: 2px;
}

.showModelContent {
  background: #fff;
  position: relative;
  width: 1100px;
  min-height: 1500px;
  margin: 0 auto;
  box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 50px;
  padding-bottom: 100px;

  .wordContentTitle {
    font-size: 30px;
    text-align: center;
    padding: 10px 0;
    font-weight: 600;
  }

  .modelHeader {
    div {
      font-size: 16px;
    }

    .modelHeaderTitle {
      border-bottom: 1px solid #000;
    }

    .squareLeft {
      border: 1px solid #c4c4c4;
      border-top: none;
      border-left: none;
      width: 30px;
      height: 30px;
    }

    .squareRight {
      border: 1px solid #c4c4c4;
      border-top: none;
      border-right: none;
      width: 30px;
      height: 30px;
    }
  }

  .wordPage {
    position: absolute;
    bottom: 50px;
    width: 1000px;
    height: 30px;
    font-size: 20px;

    .squareLeft {
      border: 1px solid #c4c4c4;
      border-bottom: none;
      border-left: none;
      width: 30px;
      height: 30px;
    }

    .squareRight {
      border: 1px solid #c4c4c4;
      border-bottom: none;
      border-right: none;
      width: 30px;
      height: 30px;
    }
  }

  .wordCodeTable {
    width: 350px;
    margin-left: auto;
    border: 2px solid #000;
    margin-bottom: 150px;

    .wordCodeTitle {
      width: 40%;
      height: 43px;
      border-right: 1px solid #000;
      font-size: 18px;
    }

    .wordCodeContent {
      width: 60%;
      height: 43px;
      font-size: 16px;

      div {
        letter-spacing: 0px;
      }

      /deep/input {
        height: 42px;
        line-height: 42px;
      }
    }
  }

  .projectTitle div {
    font-size: 45px !important;
  }

  .projectTitle {
    margin-bottom: 500px;
  }

  .wordSetContent {
    .wordTable {
      border: 1px solid #000;

      .wordTableRow {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #000;
        position: relative;

        .itemTitle {
          font-size: 20px;
          padding: 0 10px;
          height: 100%;
          display: flex;
          align-items: center;
          overflow: hidden;
        }

        .itemContent {
          min-height: 60px;
          overflow: hidden;

          div {
            font-size: 20px;
          }

          /deep/.el-range-editor--small.el-input__inner {
            height: 58px;
          }

          /deep/.el-textarea__inner {
            height: 58px;
          }

          /deep/.el-range-separator {
            padding: 0;
            line-height: 50px;
            font-size: 20px;
            width: 20px;
          }

          /deep/.el-range__close-icon {
            font-size: 20px;
            line-height: 53px;
          }
        }
      }

      .wordTableRow2 {
        display: flex;
        align-items: center;
        position: relative;

        .itemTitle {
          font-size: 20px;
          padding: 0 10px;
          display: flex;
          align-items: center;
          overflow: hidden;
        }

        .itemContent {
          min-height: 60px;
          overflow: hidden;

          div {
            font-size: 20px;
          }

          /deep/.el-range-editor--small.el-input__inner {
            height: 58px;
          }

          /deep/.el-range-separator {
            padding: 0;
            line-height: 50px;
            font-size: 20px;
            width: 20px;
          }

          /deep/.el-input__inner {
            padding-right: 0;
          }

          /deep/.el-range__close-icon {
            font-size: 20px;
            line-height: 53px;
          }
        }
      }

      .setContainer:hover .tool {
        display: block;
      }

      .wordTableRow:last-child {
        border: none;
      }
    }

    .delText {
      cursor: pointer;
    }

    .delText:hover {
      color: #f56c6c;
    }

    .rightBorder {
      border-right: 1px solid #000;
    }

    .topBorder {
      border-top: 1px solid #000;
    }

    .leftBorder {
      border-left: 1px solid #000;
    }

    .bottomBorder {
      border-bottom: 1px solid #000;
    }

    .wordSign {
      height: 100%;
      padding-top: 250px;

      .wordSignContent {
        text-align: justify;
        margin-left: 50px;
      }
    }

    .tool {
      display: block;
      position: absolute;
      right: -31px;
      width: 30px;
      height: 60px;
      background: #f1f2f4;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);

      .eicon {
        font-size: 20px;
        color: #666;
        cursor: pointer;
        height: 30px;
        width: 30px;
        text-align: center;
        line-height: 30px;
      }

      .el-icon-delete {
        color: #f56c6c;
      }
    }

    .allShow {
      width: 100%;
      height: 60px;
      border: none;
    }

    /deep/.allShow input {
      width: 100%;
      font-size: 20px !important;
    }

    /deep/input {
      height: 56px;
      line-height: 56px;
      font-size: 20px !important;
    }

    .iconCheck {
      border: 1px solid #000;
      height: 20px;
      width: 20px;
    }

    .underline {
      border-bottom: 1px solid #000;
    }

    .wordTableBigTitle {
      padding: 15px 10px;
      border: 1px solid #000;
      font-weight: 600;
    }

    .insert {
      border-top: none;
    }

    .wordTableTitle {
      padding: 15px 10px;
      border: 1px solid #000;
    }

    .wordTableTitle.insert {
      border-top: none;
    }

    .wordSetTextarea {
      border: 1px solid #000;
      border-top: none;
    }

    .wordTextareaContent {
      padding: 0 10px;
      text-indent: 45px;
      text-align: justify;
      line-height: 40px;
    }
  }
}
</style>