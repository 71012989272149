<template>
    <div>
        <div class="showModelContent">
            <div class="modelHeader">
                <div class="flex-a-b-c mb10">
                    <div class="squareLeft"></div>
                    <div class="squareRight"></div>
                </div>
            </div>
            <div class="pl30 pr30 wordSetContent">
                <div class="flex-a-c">
                    <span>项目编号：</span>
                    <div class="zdhq">{{ checkedCompany.shortName }}</div>
                    <span>-GO-R003 </span>
                </div>
                <div class="wordTable w100p marginAuto">
                    <div class="wordTableRow h40">
                        <div class="itemTitle rightBorder flex-c-c w150">项目名称</div>
                        <div class="itemTitle rightBorder flex-c-c w380 curPointer" title="点击复制" @click="copyFun(checkedProject.projectName)">
                            <div class="zdhq">{{ checkedProject.projectName }}</div>
                        </div>
                        <div class="itemTitle rightBorder flex-c-c w120">起止时间</div>
                        <div class="itemTitle flex-c-c w280">
                            <div class="zdhq"><span class="curPointer" title="点击复制" @click="copyFun(checkedProject.startDay)">{{ checkedProject.startDay}}</span> 至 <span class="curPointer" title="点击复制" @click="copyFun(checkedProject.endDay)">{{checkedProject.endDay }}</span></div>
                        </div>
                    </div>
                    <div class="wordTableRow h40">
                        <div class="itemTitle rightBorder flex-c-c w150">技术领域</div>
                    </div>
                    <div class="wordTableRow h40">
                        <div class="itemTitle rightBorder flex-c-c w150">技术来源</div>
                        <div class="itemTitle flex-c-c w780">企业自有技术</div>
                    </div>
                    <div class="wordTableRow h120">
                        <div class="itemTitle rightBorder flex-c-c w150 textc">研发经费总预算（万元）</div>
                        <div class="itemTitle rightBorder w120 flex-c-c">
                            {{ checkedProject.budget }}
                        </div>
                        <div class="itemTitle rightBorder flex-c-c w150 textc">研发经费近3年总支出（万元）</div>
                        <div class="itemTitle rightBorder w110 flex-c-c">
                            {{ budgets }}
                        </div>
                        <div class="itemTitle flex-c-c w120 rightBorder">
                            其中：
                        </div>
                        <div style="width: 287px;">
                            <div class="d-flex" :class="{ 'bottomBorder': index < formData.yearMoney.length - 1 }"
                                v-for="(row, index) in formData.yearMoney" :key="index">
                                <div class="rightBorder h40 flex-c-c w120" style="height:38px">
                                    <el-date-picker v-model="row.year" type="year" value-format="yyyy" placeholder="年份">
                                    </el-date-picker>
                                </div>
                                <div class="ohidden flex-c-c" style="height:38px">
                                    <el-input type="number" placeholder="费用" v-model="row.money"></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wordTableRow h40">
                        <div class="itemTitle rightBorder flex-c-c w150">技术关键词</div>
                        <div class="itemTitle flex-c-c" style="width:788px">
                            <el-input type="text" maxlength="300" show-word-limit :placeholder="placeholderData.keyWord"
                                v-model="formData.keyWord"
                                @focus="keyPosition('market', '技术关键词', placeholderData.keyWord)"></el-input>
                        </div>
                    </div>
                    <div class="wordTableRow h300">
                        <div class="itemTitle rightBorder flex-c-c w150 textc">
                            <div>
                                <div>立项目的及</div>
                                <div>组织实施方式</div>
                                <div>（限400字）</div>
                            </div>
                        </div>
                        <div class="itemContent w780 posre pl10">
                            <el-input type="textarea" maxlength="1000" show-word-limit :rows="11"
                                :placeholder="placeholderData.targetWay" v-model="formData.targetWay"
                                @focus="keyPosition('market', '立项目的及组织实施方式', placeholderData.targetWay)"></el-input>
                        </div>
                    </div>
                    <div class="wordTableRow h300">
                        <div class="itemTitle rightBorder flex-c-c w150 textc">
                            <div>
                                <div>核心技术及</div>
                                <div>创新点</div>
                                <div>（限400字）</div>
                            </div>
                        </div>
                        <div class="itemContent w780 posre pl10">
                            <el-input type="textarea" maxlength="1000" show-word-limit :rows="11"
                                :placeholder="placeholderData.newIdea" v-model="formData.newIdea"
                                @focus="keyPosition('market', '立项目的及组织实施方式', placeholderData.newIdea)"></el-input>
                        </div>
                    </div>
                    <div class="wordTableRow h300">
                        <div class="itemTitle rightBorder flex-c-c w150 textc">
                            <div>
                                <div>取得的阶段</div>
                                <div>性成果</div>
                                <div>（限400字）</div>
                            </div>
                        </div>
                        <div class="itemContent w780 posre pl10">
                            <el-input type="textarea" maxlength="1000" show-word-limit :rows="11"
                                :placeholder="placeholderData.result" v-model="formData.result"
                                @focus="keyPosition('market', '立项目的及组织实施方式', placeholderData.result)"></el-input>
                        </div>
                    </div>
                </div>

            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { getNormalWordDataByPdId } from "@/api/projectAdmin";
import { getProjectMoneyCountList } from "@/api/projectMoney";
import { copyText } from "@/utils";
export default {
    props: ['projectData', 'assignment', 'modelData'],
    data() {
        return {
            checkedProject: {},
            checkedCompany: {},
            formData: {
                yearMoney: [],
                keyWord: "",
                targetWay: "",
                newIdea: "核心技术：\n\n创新点：\n",
                result: ""
            },
            placeholderData: {
                keyWord: "技术关键词",
                targetWay: "立项目的及组织实施方式",
                newIdea: "核心技术及创新点",
                result: "取得的阶段性成果"
            },
            gptIcon: this.$store.state.ossUrl + "yimai_photos/iconFont/chatGTP02.png",
            departmentData: {},
        }
    },
    computed: {
        budgets() {
            let budget = 0;
            for (let row of this.formData.yearMoney) {
                budget += Number(row.money);
            }
            return budget;
        }
    },
    watch: {
        //监听赋值数据，赋值
        assignment: {
            handler(data) {
                if (data.key) {
                    if (data.key.indexOf("_") != -1) {
                        let keys = data.key.split("_");//0 key 1 列表key 2 下标
                       //-console.log(keys);
                        this.formData[keys[0]][keys[2]][keys[1]] = data.gptResult;
                        // for (let row of this.formData[keys[0]]) {
                        //     row[keys[1]] = data.gptResult;
                        // }
                    } else {
                        this.formData[data.key] = data.gptResult;
                    }
                    if (this.$refs[data.key]) {
                        this.$refs[data.key].focus();
                    }
                }
            },
            deep: true,
            immediate: true
        },
        //监听填写数据
        modelData: {
            async handler(data) {
               //-console.log(data);
                if (data && JSON.stringify(data) != '{}') {
                    await this.createData();
                    for (let key in data) {
                        let arrKey = ['yearMoney']
                        if (key != 'cycle') {
                            if (arrKey.indexOf(key) != -1) {
                                this.formData[key] = data[key] ? JSON.parse(data[key]) : [];
                            } else {
                                this.formData[key] = data[key] ? data[key] : "";
                            }
                        }
                    }
                } else {
                    this.formData = {
                        yearMoney: [],
                        keyWord: "",
                        targetWay: "",
                        newIdea: "核心技术：\n\n创新点：\n",
                        result: ""
                    };
                    this.createData();
                }
            },
            deep: true
        }
    },
    methods: {
        /**@method 复制 */
        copyFun(text) {
            copyText(text)
            this.$message.success('复制成功！');
        },
        /**@method 定位要赋值的数据 */
        keyPosition(key, title, problem = "") {
            this.$emit("keyPosition", { key, title, problem });
        },
        createData() {
            let checkedProject = this.projectData;//项目数据
            this.checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
            this.checkedProject = {
                ...checkedProject,
                //研发开始年
                year: checkedProject.startDay ? checkedProject.startDay.slice(0, 4) : "",
                //编制日期
                compileDate: this.convertChinese((new Date(checkedProject.projectDay).getTime() + (86400000 * 10))),
                //研发开始只显示到月
                startMonth: checkedProject.startDay ? checkedProject.startDay.slice(0, 7) : "",
                //研发结束只显示到月
                endMonth: checkedProject.endDay ? checkedProject.endDay.slice(0, 7) : "",
                //转中文
                projectDayText: this.convertChinese(checkedProject.projectDay),
                startDayText: this.convertChinese(checkedProject.startDay),
                endDayText: this.convertChinese(checkedProject.endDay),
            };
            let yearMoney = [];
            if (this.checkedProject.startDay.slice(0, 4) == this.checkedProject.endDay.slice(0, 4)) {
                yearMoney = [{
                    year: checkedProject.startDay.slice(0, 4),
                    money: checkedProject.budget,
                },
                {
                    year: "",
                    money: ""
                },
                {
                    year: "",
                    money: ""
                }]
            } else {
                yearMoney = [{
                    year: checkedProject.startDay.slice(0, 4),
                    money: checkedProject.budget / 2,
                },
                {
                    year: checkedProject.endDay.slice(0, 4),
                    money: checkedProject.budget / 2,
                },
                {
                    year: "",
                    money: ""
                }]
            }
            // 1: "产品",
            // 2: "软件",
            // 3: "技术",
            // 4: "设备"
            let result = "";
            if (checkedProject.projectType == 1) {
                result = `1、本项目形成一种${checkedProject.projectName}新产品。`;
            } else if (checkedProject.projectType == 2) {
                result = `1、本项目形成一种${checkedProject.projectName}新产品。`;
            } else if (checkedProject.projectType == 3) {
                result = `1、本项目形成一种${checkedProject.projectName}新技术。`;
            } else if (checkedProject.projectType == 4) {
                result = `1、本项目形成一种${checkedProject.projectName}新设备。`;
            }
            this.formData.targetWay = `立项目的：\n\n实施方式：\n本项目通过我司独立自主研究方式进行研发，项目研发资金来源于企业自筹，研发场地设在我公司技术研发中心，通过成立以项目经理负责制的项目组进行开发，项目组下设项目经理1名，项目经理负责对项目的需求、研发方案、开发进度、项目质量、研发成本进行全面控制，确保在计划开发期内取得计划的研发开发成果。本项目由${checkedProject.headName ? checkedProject.headName : '(项目负责人)'}作为项目带头人，带领多名工程师进行开发，确保项目目标达成。`;
            this.formData.yearMoney = yearMoney;
            // this.placeholderData = {
            //     market: `我司立项${checkedProject.projectName}，请写出该项目的可行性市场分析，不要分点，150字以内。`,//市场分析
            //     product: `我司立项${checkedProject.projectName}，请写出该项目的产品概念分析，不要分点，150字以内`,//产品概念分析
            //     economic: `我司立项${checkedProject.projectName}，请写出该项目的经济分析，不要分点，150字以内`,//经济分析
            //     difficult: `我司立项${checkedProject.projectName}，请写出该项目的技术可行性难度分析，不要分点，150字以内。`,
            // }
            return Promise.all([
                new Promise(open => {
                    let params = {
                        year: checkedProject.startDay.slice(0, 4),
                        pcId: this.checkedCompany.pcId,
                    };
                    getProjectMoneyCountList(params).then(res => {
                        if (res.data) {
                            for (let row of res.data.projectDataList) {
                                if (row.projectNo == checkedProject.projectNo) {
                                    this.formData.yearMoney[0].money = Number((row.researchMoney / 10000).toFixed(2));
                                    break;
                                }
                            }
                        }
                        open();
                    }).catch(err => {
                        open();
                    })
                }),
                new Promise(open => {
                    getNormalWordDataByPdId({
                        pdId: checkedProject.pdId,
                        modelType: 1
                    }).then(res => {
                        if (res.data) {
                            //1.2项目研究开发的目的及意义
                            if (res.data.wordData.objective) {
                                this.formData.targetWay = `立项目的：\n${res.data.wordData.objective}\n实施方式：\n本项目通过我司独立自主研究方式进行研发，项目研发资金来源于企业自筹，研发场地设在我公司技术研发中心，通过成立以项目经理负责制的项目组进行开发，项目组下设项目经理1名，项目经理负责对项目的需求、研发方案、开发进度、项目质量、研发成本进行全面控制，确保在计划开发期内取得计划的研发开发成果。本项目由${checkedProject.headName ? checkedProject.headName : '(项目负责人)'}作为项目带头人，带领多名工程师进行开发，确保项目目标达成。`;
                            }
                            //1.2项目关键核心技术
                            if (res.data.wordData.core) {
                                this.placeholderData.keyWord = res.data.wordData.core + ",请写出2个技术关键词。输出格式=技术关键词1；技术关键词2";
                            }
                            //1.2项目关键核心技术
                            //1.3项目技术创新点
                            if (res.data.wordData.core || res.data.wordData.innovate) {
                                this.formData.newIdea = `核心技术：\n${res.data.wordData.core ? res.data.wordData.core : ""}\n创新点：\n${res.data.wordData.innovate ? res.data.wordData.innovate : ""}`;
                            }
                            if (res.data.wordData.result) {
                                result += res.data.wordData.result;
                            }
                        }
                        result += `\n2、本项目已申请获得XXXX一项，专利号/登记号：XXXX\n3、项目经检测体验良好，功能完全实现且达到了预期效果，并已编写了其操作说明书。`
                        if (checkedProject.projectType == 1) {
                            result += `\n4、本项目已完成了科技成果转化，形成公司高新技术产品，产品已经上市，获得客户好评，产生了良好的经济效益和社会效益。`;
                        } else if (checkedProject.projectType == 2) {
                            result += `\n4、本项目已完成了科技成果转化，形成公司高新技术产品，产品已经上市，获得客户好评，产生了良好的经济效益和社会效益。`;
                        } else if (checkedProject.projectType == 3) {
                            result += `\n4、本项目已完成了科技成果转化，形成一种新技术，并成功应用到我司业务中，使其具备了业界领先的市场竞争力，为企业创造出良好的经济效益和社会效益。`;
                        } else if (checkedProject.projectType == 4) {
                            result += `\n4、本项目已完成了科技成果转化，形成一种新设备，并成功应用到我司业务中，有效提高了工作效率，节省成本，为企业创造出良好的经济效益和社会效益。`;
                        }
                        this.formData.result = result;
                        open();
                    }).catch(err => {
                        result += `\n2、本项目已申请获得XXXX一项，专利号/登记号：XXXX\n3、项目经检测体验良好，功能完全实现且达到了预期效果，并已编写了其操作说明书。`
                        if (checkedProject.projectType == 1) {
                            result += `\n4、本项目已完成了科技成果转化，形成公司高新技术产品，产品已经上市，获得客户好评，产生了良好的经济效益和社会效益。`;
                        } else if (checkedProject.projectType == 2) {
                            result += `\n4、本项目已完成了科技成果转化，形成公司高新技术产品，产品已经上市，获得客户好评，产生了良好的经济效益和社会效益。`;
                        } else if (checkedProject.projectType == 3) {
                            result += `\n4、本项目已完成了科技成果转化，形成一种新技术，并成功应用到我司业务中，使其具备了业界领先的市场竞争力，为企业创造出良好的经济效益和社会效益。`;
                        } else if (checkedProject.projectType == 4) {
                            result += `\n4、本项目已完成了科技成果转化，形成一种新设备，并成功应用到我司业务中，有效提高了工作效率，节省成本，为企业创造出良好的经济效益和社会效益。`;
                        }
                        this.formData.result = result;
                        open();
                    })
                })
            ])
        },
        convertChinese(time) {
            if (time) {
                let newDate = new Date(time)
                let y = newDate.getFullYear();
                let m = newDate.getMonth() + 1;
                let d = newDate.getDate();
                return y + '年' + (m < 10 ? '0' + m : m) + '月' + (d < 10 ? '0' + d : d) + '日'
            }
            return "";
        },
    }
}
</script>
<style lang="scss" scoped>
.companyLogo {
    height: 100px;
    position: absolute;
    top: 100px;
    left: 80px;
}

.gptIcon {
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 25px;
    width: 25px;
    z-index: 4;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
}

.zdhq {
    // color: #E6A23C !important;
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;

    /deep/input {
        border: none;
        color: #E6A23C; // #000;
    }

    /deep/.el-textarea__inner,
    /deep/.allShow .el-input__inner {
        border: none;
        padding: 0px 15px;
        color: #E6A23C; // #000;
    }
}

.wordSetContent div {
    font-size: 18px;
    letter-spacing: 2px;
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 auto;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .wordContentTitle {
        font-size: 30px;
        text-align: center;
        padding: 10px 0;
        font-weight: 600;
    }

    .modelHeader {
        div {
            font-size: 16px;
        }

        .modelHeaderTitle {
            border-bottom: 1px solid #000;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;
        font-size: 18px;

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordCodeTable {
        width: 350px;
        margin-left: auto;
        border: 2px solid #000;
        margin-bottom: 150px;

        .wordCodeTitle {
            width: 40%;
            height: 43px;
            border-right: 1px solid #000;
            font-size: 18px;
        }

        .wordCodeContent {
            width: 60%;
            height: 43px;
            font-size: 16px;

            /deep/input {
                height: 42px;
                line-height: 42px;
            }
        }
    }

    .projectTitle div {
        font-size: 45px !important;
    }

    .projectTitle {
        margin-bottom: 200px;
    }

    .wordSetContent {
        .wordTable {
            border: 1px solid #000;

            .wordTableRow {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #000;
                position: relative;

                .childTable {
                    display: flex;
                    align-items: center;
                }

                .tool {
                    display: block;
                    position: absolute;
                    right: -61px;
                    width: 60px;
                    height: 60px;
                    background: #f1f2f4;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);

                    .eicon {
                        font-size: 18px;
                        color: #666;
                        cursor: pointer;
                        height: 30px;
                        width: 30px;
                        text-align: center;
                        line-height: 30px;
                    }

                    .el-icon-delete {
                        color: #F56C6C;
                    }
                }

                .allShow {
                    width: 100%;
                    height: 40px;
                    border: none;
                }

                /deep/.allShow input {
                    width: 100%;
                    font-size: 18px !important;
                }

                /deep/input {
                    height: 38px;
                    line-height: 38px;
                    font-size: 18px !important;
                }

                /deep/.el-input__icon {
                    line-height: 38px;
                    font-size: 18px !important;
                }

                .iconCheck {
                    border: 1px solid #000;
                    height: 20px;
                    width: 20px;
                }

                .itemTitle {
                    font-size: 18px;
                    padding: 0 10px;
                    height: 100%;
                    overflow: hidden;
                }

                .itemContent {
                    min-height: 40px;
                    overflow: hidden;

                    div {
                        font-size: 18px;
                    }

                    /deep/.el-range-editor--small.el-input__inner {
                        height: 58px;
                    }

                    /deep/.el-range-separator {
                        padding: 0;
                        line-height: 50px;
                        font-size: 18px;
                        width: 20px;
                    }

                    /deep/.el-input__inner {
                        padding-right: 0;
                    }

                    /deep/.el-range__close-icon {
                        font-size: 18px;
                        line-height: 53px;
                    }
                }

                .rightBorder {
                    border-right: 1px solid #000;
                }

                .topBorder {
                    border-top: 1px solid #000;
                }

                .leftBorder {
                    border-left: 1px solid #000;
                }

                .bottomBorder {
                    border-bottom: 1px solid #000;
                }

                .wordSign {
                    height: 100%;
                    padding-top: 250px;

                    .wordSignContent {
                        text-align: justify;
                        margin-left: 50px;
                    }
                }
            }

            .wordTableRow:hover .tool {
                display: block;
            }

            .wordTableRow:last-child {
                border: none;
            }
        }

        .iconCheck {
            border: 1px solid #000;
            height: 20px;
            width: 20px;
        }

        .underline {
            border-bottom: 1px solid #000;
        }

        .wordTableBigTitle {
            padding: 15px 10px;
            border: 1px solid #000;
            font-weight: 600;
        }

        .insert {
            border-top: none;
        }

        .wordTableTitle {
            padding: 15px 10px;
            border: 1px solid #000;
        }

        .wordTableTitle.insert {
            border-top: none;
        }

        .wordSetTextarea {
            border: 1px solid #000;
            border-top: none;

            .wordTextareaContent {
                text-indent: 45px;
                text-align: justify;
                line-height: 40px;
            }
        }
    }
}
</style>